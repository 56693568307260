<div class="battlegrounds-theme wrapper">
	<div class="container" *ngIf="initComplete$ | async; else loadingState">
		<website-top-bar></website-top-bar>
		<div class="main">
			<website-navigation></website-navigation>
			<div class="content-container overlay-container-parent">
				<div class="content">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</div>
	<ng-template #loadingState> Loading... </ng-template>
</div>
